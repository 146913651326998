<template>
    <div>
        <div class="flex_b">
            <div>
                <el-button type="primary" @click="addBtn" size="mini">添加会员</el-button>
            </div>
        </div>
        <br>
        <div class="searchForm">
            <div>
                会员姓名： <el-input size="small" v-model="search_form.uname" placeholder="请输入会员姓名"  style="width:150px;"></el-input>
            </div>
            <div>
                会员地址： <el-select v-model="search_form.member_address" placeholder="请选择" size="small">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.label">
                                </el-option>
                            </el-select>
            </div>
            <div>
                会员手机号： <el-input size="small" v-model="search_form.phone" placeholder="请输入会员手机号"  style="width:150px;"></el-input>
            </div>
            <div>
                添加时间：  <el-date-picker
								size="small"
								v-model="search_form.time"
								type="datetimerange"
								value-format="yyyy-MM-dd HH:mm:ss"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期">
                           </el-date-picker>
            </div>
            <div>
                会员生日：
                  <el-date-picker
                      size="small"
                       value-format="MM-dd"
                       format="MM-dd"
                       v-model="search_form.birthday"
                       placeholder="选择会员生日">
                   </el-date-picker>
            </div>
            <div>
               状态：
                    <el-radio v-model="search_form.status" label="">不限</el-radio>
                    <el-radio v-model="search_form.status" label="0">禁用</el-radio>
                    <el-radio v-model="search_form.status" label="1">启用</el-radio>
            </div>
            <div>
                <el-button type="primary" size="mini" @click="search">检索</el-button>
                <el-button size="mini" @click="clearSearch">重置/检索</el-button>
            </div>
            <br>
            <el-button style="float: right;" type="primary" size="mini" @click="downlode">下载</el-button>
            <div style="clear: both"></div>
        </div>

        <br>
        <el-table  :data="memberList"   border  style="width: 100%" >
           <el-table-column   align="center"       label="会员头像">
              <template slot-scope="scope">
                  <div>
					   <bigImg v-if="isShowListImg" :furl="$utils.defaultImg(scope.row.header_img)"></bigImg>
                  </div>
              </template>
           </el-table-column>
            <!-- <el-table-column   align="center"   label="会员画像">
              <template slot-scope="scope">
                  <div>
					  <bigImg v-if="isShowListImg" :furl="$utils.defaultImg(scope.row.set_header_img)"></bigImg>
                  </div>
              </template>
            </el-table-column> -->
            <el-table-column   align="center"    prop="uname"    label="会员姓名"></el-table-column>
            <el-table-column  align="center"   prop="member_address"  label="会员地址"></el-table-column>
            <el-table-column    align="center"     prop="phone"  label="会员手机号"> </el-table-column>
            <el-table-column  label="会员生日"  align="center" prop="birthday"></el-table-column>
            <el-table-column  label="会员性别"  align="center" prop="sex">
              <template slot-scope="scope">
                  <div>
                    {{['保密','女','男'][scope.row.sex]}}
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="添加时间"  align="center" >
              <template slot-scope="scope">
                 <div>
				   {{scope.row.add_time | formatTime}}
                 </div>
              </template>
            </el-table-column>
           <el-table-column label="状态"   align="center" >
                      <template slot-scope="scope">
                         <div>
                           <el-tag v-if="scope.row.status==1" effect="dark">启用</el-tag>
                           <el-tag v-else effect="dark" type="danger">禁用</el-tag>
                         </div>
                      </template>
            </el-table-column>

            <el-table-column  label="操作"  fixed="right"  align="center">
                <template slot-scope="scope">
					<el-button v-if="couponList.length" @click="sendCouponBtn(scope.row)" type="text" size="small">发送优惠券</el-button>
                    <el-button @click="update(scope.row)" type="text" size="small">更新</el-button>
                    <el-button @click="updateUpwd(scope.row)" type="text" size="small">修改密码</el-button>
                    <el-button type="text" size="small" @click="del(scope)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="page">
            <el-pagination
                    background
                    @size-change="pageChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page_totle">
            </el-pagination>
        </div>
        <el-dialog
                :title="title"
                :visible.sync="show_editor_member"
                width="700px"
        >
            <el-form  :model="member_info" label-width="120px">
                <el-form-item label="会员手机号">
                    <el-input v-model="member_info.phone" style="width: 500px"></el-input>
                </el-form-item>
            <el-form-item label="会员密码" v-if="title=='添加会员'">
                <el-input type="password" v-model="member_info.upwd" style="width: 500px"></el-input>
            </el-form-item>
            <el-form-item label="会员姓名">
                <el-input v-model="member_info.uname" style="width: 500px"></el-input>
            </el-form-item>
            <el-form-item label="会员性别">
                <el-radio-group v-model="member_info.sex">
                   <el-radio label="2">男</el-radio>
                    <el-radio label="1">女</el-radio>
                 </el-radio-group>
            </el-form-item>
            <el-form-item label="状态">
                <el-radio-group v-model="member_info.status">
                   <el-radio label="0">禁用</el-radio>
                    <el-radio label="1">启用</el-radio>
                 </el-radio-group>
            </el-form-item>
            <el-form-item label="会员生日">
                 <el-date-picker
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      v-model="member_info.birthday"
                      type="datetime"
                      placeholder="选择会员生日">
                  </el-date-picker>
            </el-form-item>
            <el-form-item label="会员地址">
                <el-select v-model="member_info.member_address" placeholder="请选择" size="small">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model="member_info.remark" style="width: 500px"></el-input>
            </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editor_member = false">取 消</el-button>
                    <el-button type="primary" @click="addMember">确 定</el-button>
                </span>
        </el-dialog>
        <el-dialog
                title="修改密码"
                :visible.sync="show_editorUpwd_member"
                width="700px"
        >

          <div style="padding-left: 30px;">
            新密码：<el-input v-model="newUpwd1" type="password" style="width: 500px" placeholder="请输入密码"></el-input>
          </div> <br>
           <div style="padding-left: 30px;">
             新密码：<el-input v-model="newUpwd2" type="password" style="width: 500px" placeholder="请再次输入密码"></el-input>
           </div>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="show_editorUpwd_member = false">取 消</el-button>
                    <el-button type="primary" @click="updateMemberUpwd">确 定</el-button>
                </span>
        </el-dialog>
		<el-dialog
		        title="发送优惠券"
		        :visible.sync="show_send_coupon"
		        width="500px"
		>
		
		  <div style="padding-left: 30px;">
		    会员名称：<el-input v-model="sendCouponInfo.uname" disabled style="width: 300px" placeholder="请输入密码"></el-input>
		  </div> <br>
		   <div style="padding-left: 48px;">
		     优惠券：<el-select v-model="sendCouponInfo.coupon_id" placeholder="请选择">
						<el-option
						  v-for="item in couponList"
						  :key="item.id"
						  :label="item.couponName"
						  :value="item.id">
						</el-option>
					</el-select>
		   </div>
		    <span slot="footer" class="dialog-footer">
		            <el-button @click="show_send_coupon = false">取 消</el-button>
		            <el-button type="primary" @click="sendCoupon">确 定</el-button>
		        </span>
		</el-dialog>
    </div>
</template>
<style>

</style>
<script>
    export default {
        data(){
            return {
                options: [],
                title:'添加会员',
                pageSize:10,
                page:1,
                page_totle:0,
				show_send_coupon:false,
                show_editor_member:false,
                show_editorUpwd_member:false,
                search_form:{
                    uname:'',
                    member_address:'',
                    phone:'',
                    time:[],
                    start_time:'',
                    end_time:'',
                    birthday:'',
                    sex:'',
                    status:''
                },
                member_info:{
                    mid:null,
                    uname:'',
                    upwd:'',
                    member_address:'',
                    remark:'',
                    phone:'',
                    birthday:'',
                    sex:'2',  //1女 2男
                    status:'0' //0禁用 1启用
                },
                memberList:[],
                newUpwd1:'',
                 newUpwd2:'',
                 mid:'',
				 isShowListImg:true,
				 sendCouponInfo:{
					 mid:"",
					 coupon_id:"",
					 uname:'',
				 },
				 couponList:[]
            }
        },
        mounted(){
            document.title='会员列表'
            this.getMember()
            this.getAddress()
			this.getCouponList()
        },
        methods:{
			getCouponList(){
				let _this=this
				this.$post('/pc/coupon/getCoupon',{static:1},function (res) {
					let result = res.data
					if(result.code==200){
						 for(let item of result.data){
							 item.couponName = _this.returnCouponName(item)
						 }
						_this.couponList=result.data
					}
				})
			},
			sendCouponBtn(row){
				if(!this.couponList.length) return this.$message.error('请到营销管理中添加优惠券')
				this.sendCouponInfo.coupon_id = ''
				this.sendCouponInfo.mid = row.mid
				this.sendCouponInfo.uname = row.uname
				this.show_send_coupon = true
			},
			sendCoupon(){
				if(!this.sendCouponInfo.coupon_id) return this.$message.error('请选择优惠券')
				this.$post('/pc/coupon/sendCoupon',this.sendCouponInfo,res=>{
					let result = res.data
					if(result.code==200){
					  this.show_send_coupon = false
					  this.$message.success(result.msg)
					}
				})
			},
          updateMemberUpwd(){
             if(!this.newUpwd1) return this.$message.error('请输入新密码')
             if(this.newUpwd1!=this.newUpwd2) return this.$message.error('两次密码输入不一样')
             this.$post('/pc/member/updateMemberUpwd',{upwd:this.newUpwd1,mid:this.mid},res=>{
                let result = res.data
                if(result.code==200){
                  this.newUpwd1=''
                  this.newUpwd2=''
                  this.show_editorUpwd_member = false
                  this.$message.success(result.msg)
                }

             })
          },
          updateUpwd(row){
            this.show_editorUpwd_member =true
             this.mid = row.mid
          },
            downlode(){
                let _this = this
                if(this.search_form.time.length){
                    this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
                    this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
                }else{
                    this.search_form.start_time = ''
                    this.search_form.end_time = ''
                }

                let cid = sessionStorage.getItem('cid')

                if(this.search_form.uname){
                    var uname = '&uname=' + this.search_form.uname
                }else{
                    uname = '&uname='
                }

                if(this.search_form.member_address){
                    var member_address = '&member_address=' + this.search_form.member_address
                }else{
                    member_address = '&member_address='
                }

                if(this.search_form.phone){
                    var phone = '&phone=' + this.search_form.phone
                }else{
                    phone = '&phone='
                }

                if(this.search_form.start_time){
                    var start_time = '&start_time=' + this.search_form.start_time
                }else{
                    start_time = '&start_time='
                }

                if(this.search_form.end_time){
                    var end_time = '&end_time=' + this.search_form.end_time
                }else{
                    end_time = '&end_time='
                }
                var token = sessionStorage.getItem('token')
                 token = '&token=' + token
                let url = `${_this.$domain}/pc/member/excelMemberList?cid=${cid}${uname}${member_address}${phone}${start_time}${end_time}${token}`
               location.href = url
            },
            del(el){
                let _this=this
                if(confirm('确定删除')){

                    var index = el.$index
                    var mid=el.row.mid
                    this.$post('/pc/member/delMember',{mid:mid},function (res) {
                        let result = res.data
                        if(result.code==200){
                            _this.memberList.splice(index,1)
                            _this.page_totle--
                            _this.$message.success(result.msg)
                        }
                    })
                }
            },
            getAddress(){
                let _this=this
                this.$post('/pc/member/getAddress',{},function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.$message.success(result.msg)
                        if(result.data.member_address){
                            let address = result.data.member_address
                            let len = address.length - 1
                            address = address.slice(0,len)
                            let arr = address.split(',')

                            for(let i in arr){
                                _this.options.push({
                                    value:i,
                                    label:arr[i]
                                })
                            }
                        }
                    }
                })
            },
            isPhone(phoneNum){
                var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;
                if (!myreg.test(phoneNum)) {
                    return false;
                } else {
                    return true;
                }
            },
            search(){
                let _this = this
                if(this.search_form.time.length){
                    this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
                    this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
                }else{
                    this.search_form.start_time = ''
                    this.search_form.end_time = ''
                }
                _this.getMember()
            },
            clearSearch(){
                    this.search_form.uname=''
                    this.search_form.member_address=''
                    this.search_form.phone=''
                    this.search_form.time=[]
                    this.search_form.start_time=''
                    this.search_form.end_time=''
                    this.search_form.birthday=''
                    this.page=1
                    this.pageSize=20
                    this.getMember()
            },
            addBtn(){
                this.show_editor_member=true
                this.title='添加会员'
                this.member_info = {
                    mid:null,
                    uname:'',
                    upwd:'',
                    member_address:'',
                    remark:'',
                    nick_name:'',
                    phone:'',
                    birthday:'',
                    sex:'2',
                    status:'1',
                }

            },
            pageChange(pageSize){
                this.page=1
                this.pageSize=pageSize
                this.getMember()
            },
            handleCurrentChange(page){
                this.page=page
                this.getMember()
            },
            getMember(){
                let _this=this
                _this.search_form.page=_this.page
                _this.search_form.pageSize=_this.pageSize
                this.$post('/pc/member/memberListFilter',_this.search_form,function (res) {
                    let result = res.data
                    if(result.code==200){
						_this.isShowListImg = false
						_this.$nextTick(()=>{
							_this.memberList=result.data.result
							_this.page_totle=result.data.count
							_this.isShowListImg = true
						})
						
                    }
                })
            },
            addMember(){  //有mid是编辑 没有是添加
                let _this=this
                    if(!this.isPhone(_this.member_info.phone)){
                        return this.$message.error('手机格式有误')
                    }
                this.$post('/pc/member/addMember',_this.member_info,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.getMember()
                        _this.show_editor_member=false
                        return   _this.$message.success(result.msg)
                    }
                      _this.$message.error(result.msg)
                })
            },
            update(row){
                this.title='更新会员'
                this.show_editor_member=true
                this.member_info=row
                 this.member_info.status+=''

            },
        }
    }
</script>
